<template>
  <dashboard-layout>
    <template #content>
      <div class="level is-mobile dashboard-navbar">
        <div class="level-left">
          <div class="level-item">
            <div class="content">
              <h3 class="title has-text-primary has-text-weight-bold">
                Page Not Found
              </h3>
            </div>
          </div>
        </div>
      </div>

      <section class="section">
        <div class="container">
          <div class="columns is-vcentered is-centered">
            <div class="column is-half">
              <figure class="image is-16by9">
                <img
                  src="@/assets/images/page-not-found.svg"
                  alt="Page Not Found"
                >
              </figure>
              <div class="level">
                <div class="level-item has-text-centered">
                  <div>
                    <b-button
                      tag="router-link"
                      to="/organizations"
                      type="is-primary"
                      icon-left="chevron-left"
                    >
                      go back home
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </dashboard-layout>
</template>

<script>
import { DashboardLayout } from '@/layouts';

export default {
  name: 'PageNotFound',

  components: {
    DashboardLayout,
  },
};
</script>

<style>

</style>
